@import "../../styles/global";
.agencyValidationContainer {
	outline: 0;

	// Form contents
	form {
		@include media-breakpoint-up(lg) {
			width: 70%;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}

		.heading {
			display: block;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			font-weight: $font-weight-bold;
		}

		.subheading {
			display: block;
			margin-top: 0.2rem;
			margin-bottom: 0.0rem;

			font-weight: $font-weight-bold;
		}		
	}
}
.agencyContainer{
	margin-top: 2rem;
    margin-right: 3rem;
	background-color: white;
	padding: 1rem;
	padding-bottom: 3rem;
}

.agencyRegisterTitle{
	font-size: 40px;
	font-weight: 400;
	line-height: 48px;
	text-align: left;
	color: #010D1A;
	padding-bottom: 24px;
}

.agencyRegisterHeading{
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
	text-align: left;
	color: #000000;
}

.agencyRegisterAlert{
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	text-align: left;
	color: #000000;
}

.agencyAlertContainer{
	display: inline-flex;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	text-align: left;
	color: #000000;
	padding-bottom: 15px;
}
.agencyIcon {
	height: 24px;
	width: 24px;
	background-color: #B00121;
    border-radius: 100%;
    color: white;
    display: block;
	align-self: center;
	margin-right: 5px;
	font-size: 1.5rem;
}

.agencyAlertText{
	span {
		color: #FF6000 !important;
	}
}

.formGroup{
    padding-bottom:10px ;
}

.inputContainer {
	position: relative;

	&__label {
		@include ui-caption;
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		text-align: left;

	}

	.validationErrorMessage {
		display: block;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;

		color: $error;
		font-family: "Noto Sans";
		font-size: 14px;
		font-weight: 600;
		line-height: 21px;
	}	

	
	label {
		display: block;

		.inputWrapper {
			position: relative;

			span {
				position: absolute;
				top: 50%;
				right: 1rem;

				color: $swg-pebble;
				font-size: 80%;

				transform: translateY(-50%);

				pointer-events: none;
			}
		}

		input[type="text"],		
		select {
			display: block;
			width: 100%;
			height: 2.5rem;
			padding: 0.375rem 0.75rem;
            min-width: 120px;

			font-size: 1rem;
			font-family: inherit;
			line-height: 1.5;

			background-color: $white;

			border: 1px solid $swg-pebble;
			border-radius: 0.25rem;
			box-shadow: none;

			appearance: none;

			&[readonly] {
				background-color: $swg-pearl;
				cursor: not-allowed;
			}
		}

		// Target ie11 drop-down arrow
		select::-ms-expand {
			display: none;
		}

		select,
		input[type="password"] {
			padding-right: 2rem;
		}
	}
}

.required::after {
	color: $error;
	font-size: $font-size-base;

	content: " *";
}

.noMarginBottom {
	margin-bottom: 0;
}

.sectionButtons {
	display: flex;
	align-items: center;
	justify-content: stretch;
	margin-top: 0.5rem;

	&__btn {
		flex: 1 1 auto;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		width: 100%;
		background-color: #000A3B !important;
		border-radius: 50px;
		font-size: 20px;
		font-weight: 900;
		line-height: 19px;
		letter-spacing: 0.05em;
		color: #FFFFFF;
		text-align : center;
		& + & {
			margin-left: $gap;
		}
	}
	
	&__btn:hover {
		flex: 1 1 auto;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		width: 100%;
		background-color: #FF6000 !important;
		border-radius: 40px;
		box-shadow: 1px 1px 8px 0px #000A3B;
		border: 1px solid #000A3B;
		color: #000A3B;
		& + & {
			margin-left: $gap;
		}
	}
}

.buttonToLink {
	background: none;
	border: none;
	color: #FF6000;
 }
 
 .buttonToLink:hover {
	background: none;
	text-decoration: underline;
 }

// Heading
.sectionHeading {
	@include h5;
	display: flex;
	align-items: center;

	.sectionEdit {
		margin-left: 0.75rem;

		color: $swg-tide;
		font-size: 0.9em;
	}

	.editIcon {
		margin-right: 0.5rem;

		font-size: rem(14px);
	}

	.editText {
		font-size: $font-size-base;
		font-family: $font-family-base;
	}
}

.errorMessage {
	display: block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}	

.customValidationMessages {
	ul {
		margin-top: 1rem;
		margin-bottom: 0;
		padding-left: 0;

		list-style: none;

		li > span {
			margin-left: -1em;
		}

		li.icon {
			position: relative;

			padding-left: 1.5rem;

			color: $error;
			font-size: 0.95rem;

			&::before {
				position: relative;
				top: 0.2rem;
				left: -1.5em;

				display: inline-block;
				width: 1rem;
				height: 1rem;

				background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="' + $error +
				'" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg>');

				content: "";
			}

			&.pass {
				color: $swg-green;

				&::before {
					background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="' +
					$swg-green +
					'" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>');

					content: "";
				}
			}
		}
	}
}
