// Brand colours
$swg-sun: #ff6000;
$swg-dune: #a2978a;
$swg-stone: #595959;
$swg-tide: #005b9e;
$swg-rock: #333;
$swg-green: #358046;
$swg-water: #e2eff7;
$swg-pebble: #ccc;
$swg-sand: #d5cdbf;
$swg-sky: #eff7fc;
$swg-pearl: #f2f2f2;
$swg-sea-shell: #f3f1e9;
$swg-star-fish: #cf4b05;
$swg-coral: #ed7169;
$swg-coral-reef: #008f99;
$swg-royal: #2a3f64;
$swg-sea: #009476;
$swg-turquoise: #00a887;
$swg-beach: #00a2ad;
$swg-star: #ffb612;
$swg-ocean: #00589e;

$swg-wedding: #fb344d;
$swg-shadow: #d6d6d6;

$facebook: #6081c4;
$twitter: #43bdef;
$pinterest: #e62b33;
$youtube: #f00;
$instagram: #f09433;

$error: #B00121;
$light-error: #fff2f2;
$success: #046239;
$light-success: #ebf2ed;
$warning: #f4ae11;
$light-warning: #fff8e6;

$swg-black: #000;
$black: $swg-black;
$swg-white: #fff;
$white: $swg-white;


$border-radius: 4px;
$input-radius: $border-radius;
$wing-corner: 30px;

$input-border-color: $swg-pebble;
$input-border-radius: $border-radius;
$checkbox-size: 16px;

$header-shadow: 0 7px 6px rgba($swg-rock, 0.25);

$font-family-base: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
	"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
	"Segoe UI Symbol", "Noto Color Emoji";

$font-family-muller-narrow: "MullerNarrow";
$body-color: $swg-rock;
$body-bg: $swg-white !default;

$link-color: $swg-tide;
$link-hover-color: $swg-tide;

$font-size-base: 1rem;
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-bold: 700;
$headings-font-weight: 700;
$font-weight-base: $font-weight-normal;
$grid-gutter-width: 0.5rem !default;
$gap: 16px !default;
$gap-mobile: 12px !default;
$card-shadow: 0 rem(7px) rem(21px) rgba(0, 0, 0, 0.129);
$side-menu-shadow: rem(-5px) rem(9px) rem(16px) rgba(0, 0, 0, 0.29);
$page-padding: 1rem;
$headings-margin-bottom: 8px;
$paragraph-margin-bottom: $page-padding;
$line-height-base: 1.5 !default;
$inner-box-padding-x: rem(10px);
$section-spacing: 3rem;

$sizeSm: 30px;
$sizeMd: 40px;
$sizeLg: 60px;

$enable-pointer-cursor-for-buttons: true !default;

$page-width: 1140px;
