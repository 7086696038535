@import "../../../styles/global";
.dots {
  position: relative;
  display: inline-flex;
  flex: 1 0 auto;
  align-items: center;
  width: 2em;
  height: 2em;

  span {
    position: absolute;

    width: 10px;
    height: 10px;
    margin-right: 5px;

    background: rgba($swg-sun, 0.5);
    background: $swg-sun;
    border-radius: 50%;

    animation: dots4 1.7s infinite ease-in-out both;

    &:nth-child(1) {
      left: 0;

      animation-delay: 0.6s;
    }

    &:nth-child(2) {
      left: 15px;

      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      left: 30px;

      animation-delay: 0.2s;
    }

    &:nth-child(4) {
      left: 45px;

      animation-delay: 0.1s;
    }
  }

  @keyframes dots4 {
    0%,
    80%,
    100% {
      transform: scale(0);
      opacity: 0;
    }

    40% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes dots4 {
    0%,
    80%,
    100% {
      transform: scale(0);
      opacity: 0;
    }

    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
