@import "../../styles/global";

.starPointsSummary {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  &Item {
    color: #333;
    margin-bottom: 0;
    position: relative;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      margin-right: 50px;
    }

    @include media-breakpoint-down(sm) {
      width: calc(50% - 50px);
      margin-bottom: 1.5rem;
    }

    p:last-child {
      font-size: 1.5rem;
      color: #595959;
      margin: 0.5rem 0;
    }
  }
}

.budget {
  margin: 0 3px 3px 0;
}

.highlighted {
  color: $swg-sun !important;
}
