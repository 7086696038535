@import "../../styles/global";

.agentToolCards {
  p {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    margin-bottom: 0.25rem;
  }

  a > span {
    font-family: $font-family-base;
    font-size: 1rem;
    color: $swg-rock;
  }

  img {
    @include media-breakpoint-up(lg) {
      height: 210px;
    }
  }
}

.agentToolsBackLink {
  margin-top: -2.5rem;
}

.agentToolsVwq {

  &Desc {
    margin-bottom: 2rem;
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
  }

  &Image {
    img {
      height: 210px;
      object-fit: cover;
    }
  }

  &PostDate {
    margin-top: 1rem !important;
    margin-bottom: 0;
    color: #017F7C;
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  &Heading {
    margin: 0.25rem 0;
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 100;
    line-height: 26px;
  }

  &Link {
    @include link-hover($swg-tide);
    color: #017F7C;
  }

  &VideoLink {
    margin-right: 1.5rem;
    color: #017F7C;
  }

  &PlayLink {
    margin: 0 2px 2px 0;
    color: #017F7C;
  }

  &DownloadLink {
    font-size: 0.9rem;
    margin: 0 3px 2px 0;
    color: #017F7C;
  }

  &BackLink {
    margin-top: -2.5rem;
  }

  &SearchBar {
    float: right;
    margin-right: 10rem;
    top : 70px;
    position: relative;
  }

  &header {
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  :global {
    .Heading_module_heading_H1__9ad4c810 {
      font-family: "Noto Sans";
      font-size: 60px;
      font-weight: 200;
      line-height: 72px;

      @media (max-width: 600px) {
        color: #000;
        font-family: "Noto Sans";
        font-size: 40px;
        font-weight: 200;
        line-height: 48px;
        text-transform: none;
      }
    
    }
  }
}