@import "../../../styles/global";
.containerGrid {
	display: flex;
	flex: 1 0 100%;
	flex-wrap: wrap;
	justify-content: start;
	width: 100%;

	& > section {
		width: 100%;
		margin: rem(5px);

		@include media-breakpoint-up(md) {
			flex: 1 0 calc(50% - 10px);
			max-width: calc(50% - 10px);
		}
	}

	&.threeColumn > section {
		@include media-breakpoint-up(lg) {
			flex: 1 0 calc(33% - 10px);
			max-width: calc(33% - 10px);
		}
	}

	&.fourColumn > section {
		@include media-breakpoint-up(lg) {
			flex: 1 0 calc(25% - 10px);
			max-width: calc(25% - 10px);
		}
	}
}

.loadMore {
	display: block;
	width: fit-content;
	margin: rem(30px) auto 0;

	color: $swg-tide;

	background-color: transparent;

	border: 0 solid transparent;
}

.loadMoreIcon {
	margin-left: rem(5px);
}
