@import "../../styles/global";

#okta-sign-in-container {
  .okta-form-title.o-form-head {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    color: #010D1A;

    @media (max-width: 600px) {
      max-width: 316px;
    }
  }

  #okta-sign-in {
    margin: 2rem;

    &.main-container {
      box-shadow: 0 2px 15px #00000029;

      @include media-breakpoint-down(md) {
        width: 500px;
      }

      @media (max-width: 600px) {
        border: 1px solid $swg-pebble;
        max-width: 400px;
        margin: 0 auto;
      }

      @media (max-width: 480px) {
        width: auto;
        margin: 0 auto;
      }
    }

    &.auth-content {
      @include media-breakpoint-down(md) {
        max-width: 316px;
        margin: 0 auto;
      }
    }
    .auth-footer {
      a:link {
        color: #FF6000;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
      }
    }
    .registration-container {
      .registration-label {
        color: #010D1A;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
      }

      a:link {
        color: #FF6000;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
      }
    }
  }

  .okta-sign-in-header.auth-header {
    border-bottom: 0;
    padding: 0;
  }

  .o-form label {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #666666;
  }

  .auth-container .okta-form-input-field:hover {
    border: 1px solid #000A3B;    
  }

  .auth-container .okta-form-input-field:focus {
    border: 1px solid #000A3B;    
  }

  .auth-container .okta-form-input-field .focused-input {
    border: 1px solid #000A3B;    
  }

  .auth-container .button.button-primary.link-button-disabled {
    background: #000A3B;   
  }

  .o-form-button-bar .button {
    background: #000A3B;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 900;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
  }

  .o-form-button-bar .button:hover {
    background: #FF6000;
    font-size: 20px;
    font-weight: 900;
    line-height: 21px;
    border-radius: 40px;
    border-color: #000A3B;
    box-shadow: 1px 1px 8px 0px #000A3B;
    border: 1px solid #000A3B;
    color: #000A3B;
  }

  .o-form-button-bar .button:focus {
    background: #000A3B;
    font-size: 20px;
    font-weight: 900;
    line-height: 21px;
    border-radius: 40px;
    border-color: #000A3B;
    box-shadow: 1px 1px 8px 0px #000A3B;
    border: 1px solid #000A3B;
    color: #000A3B;
  }

  .o-form .custom-checkbox label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #000000;
    background-size: 0px 0px;
  }

  .o-form .custom-checkbox label.checked {
    background-size: 0px 0px;
  }

  .custom-checkbox input {
    opacity: 1;
    top: 7px;
    visibility: visible;
    width: 16px;
    height: 16px;
    accent-color: #FF6000;
  }

  .o-form-fieldset-container .button {
    background: #000A3B;
    border-radius: 40px;
    font-size: 20px;
    font-weight: 900;
    line-height: 45px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-align : center;
  }
  
  .o-form-fieldset-container .button:hover {
    background: #FF6000;
    font-size: 20px;
    font-weight: 900;
    line-height: 45px;
    border-radius: 40px;
    border-color: #000A3B;
    box-shadow: 1px 1px 8px 0px #000A3B;
    border: 1px solid #000A3B;
    color: #000A3B;
  }

  .o-form-fieldset-container .button:focus {
    background: #000A3B;
    font-size: 20px;
    font-weight: 900;
    line-height: 45px;
    border-radius: 40px;
    border-color: #000A3B;
    box-shadow: 1px 1px 8px 0px #000A3B;
    border: 1px solid #000A3B;
    color: #000A3B;
  }

  #okta-sign-in.auth-container .link {
    color: $swg-tide;
  }

  .focused-input {
    box-shadow: 1px 1px 1px 0px #DBDCDC;
    border-radius: 2px;
    background: #FFF;
  }

  .infobox-error:before {
    background-color: #B00121;
  }

  .o-form-explain.o-form-input-error {
    color: #B00121;
  }

  .error-16-small:before {
    color: #B00121;
  }
}
