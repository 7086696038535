.agentFooterLinks {
    margin-bottom: 0.5rem;
    margin-right: 1.875rem;
  }
  
  .logoutFoot {
    height: 10%;
    background-color: #000A3B;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 60px;
  }
  
  .logoutFootContent {
    margin: auto;
    color: white;
    width: 70%;
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    
  }
  .logoutFootIconContainer {
    margin: auto;
    width: 30%;
    text-align: center;
  }
  
  .languageSelectContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  
  .languageSelectItem {
    width: 65%;
    margin-right: 0.5rem;
    :hover{
      text-decoration: underline;
    }
  }
  .languageSelectIcon{
    font-size: 16px;
  }
  
  .vwq {
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    background-color: #01172E;
  
    padding-top: 2rem;
    padding-bottom: 3rem;

    li {
      font-family: "Noto Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    :global {
      .Grid_module_grid__da3b8c09 {
        border-bottom: 1px solid #DBDCDC;
        margin-bottom: 2rem;
      }
        
      .Footer_module_footerCopyright__d5e8ed8d p{
        padding-top: 24px;
        font-family: "Noto Sans";
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      .ant-select-selector{
        background-color: transparent !important;
        color: white;
        border: 0 !important;  
        font-family: "Noto Sans";
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
  
      .ant-select-arrow {
        color: white;
      }
    }
  }