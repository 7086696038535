@import "../../styles/global";

.loginContainer {
  background-image: url('https://assets.sunwingtravelgroup.com/image/upload/v1715351285/WestJet/EMAIL/Agent%20Portal/Images/SWG_VWQ_Agent_Portal_Main.jpg');
  background-size: 100%;
  position: relative;
  height: 100%;
  top: 0;
  left: 0;
}

.loginSection {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
}

.login {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }

  &Info {

    @include media-breakpoint-down(md) {
      width: 500px;
      margin: 3rem auto;
    }

    @media (max-width: 600px) {
      max-width: 400px;
    }

    @media (max-width: 480px) {
      width: auto;
      min-width: 300px;
    }

    img {
      max-height: 240px;
      object-fit: cover;
    }

    &Features {
      &title {
        font-family: "Noto Sans";
        font-weight: 900;
					  
				
        font-size: 24px;
        line-height: 30px;
      }
      &featureBullet {
          font-family: "Noto Sans";
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
      }
    }

    &FeatureItem {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
      color: white;

      .featureIcon {
        padding-right: 1rem;

        .svgIcon {
          display: inline-block;
          vertical-align: baseline;
          color: $swg-sun;
          font-size: 1.5rem;
        }
      }
      
      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  .widgetWrapper {
    flex: 1 1 50%;
  }

  :global {
    
    .HeroCard_module_heroHeading__455b3e1f {
      font-family: "Noto Sans";
      font-weight: 900;
      font-size: 56px;
      line-height: 68px;
    }
  }

}
