.parentDiv{
    cursor: pointer;
    position: relative;
}


.iconclass{
    border-radius: 50%;
    border: 3px solid white;
    padding: 1em;
    font-size: 120%;
    color:white;
    background-color:rgba(0,0,0,0.4);
    position: absolute;
    z-index: 1;
}

.iconPrimary{
    top: 15%;
    left: 45%;
}

.iconHorizontal{
    top: 30%;
    left: 75%;
}