@import "../../styles/global";
  
.popupMainContainer {
  min-width: 20%;
  min-height: 10%;
  display:inline-flex;
  right:2%;
  margin: auto;
  border-width: 1px;
  border-style: none;
  background: #f9f9f9;
  background-color: #f9f9f9;
  border-radius: 8px;
  z-index: 21;
  a {
    color: white;
  }
}

.otherOsPosition {
  position: fixed;
  top: 12%;  
  @media (max-width: 600px){
    top: 20%;  
  }
}

.windowPosition{
  position: fixed;
  bottom: 25%;  
}

.buttonwWrapper {
  min-width: 90%;
  display:inline-flex;
  border-style: none;
  background: none;
  margin-left: -0.3rem;
}

.logoContainer {
  background-color: #ff6000;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  border-radius: 8px 0 0 8px;
  min-width: 20%;
  height: 100%;
}

.logoImage {
  background-color: #ff6000;
  height: auto; 
  width: 100%; 
  max-width: 100px; 
  max-height: 100px;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
}

.contentContainer {
  margin: .5rem;
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: space-between;
}

.contentHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  float: left;
}

.contentBody {
  font-size: 18px;
  line-height: 25px;
  text-overflow: ellipsis;
  max-width: 275px;
  text-align: left;
}

.closeIcon {
  float: right;
  margin: 0.2rem;
}

.closeButton {
  padding: 0;
  border: none;
  background: none;
  float: right;
  margin-right: 0.1rem;
}

.alertNotification {
  position: relative;
  display: inline-block;
}

.alertNotificationCircle {
  position: relative;
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  border-radius: 100%;
  text-align: center;
}

.alertNotificationCount {
  position: absolute;
  top: -0.55rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  color: #333;
  font-size: 0.75rem;
  background-color: #fff;
  border-radius: 50%;
}

.alertNotificationIcon {
  font-size: 0.8em;
}

div.notificationHub {
  background: #f9f9f9;
  border-radius: 4px;
  color: #333;
  right: 0;
  position: absolute;
  top: 100%;
  width: 40vh;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  float: left;
  
  /* mouse over link */
  a:hover {
    color: blue;
  }
  div:nth-child(1) {
    padding-top: 5px;
    padding-bottom: 10px;
  }
}
  
.webPushImage {
  max-width: 50%;
  max-height: 50%;
}