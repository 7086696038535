.paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;
}

.pagination {
    display: flex;
    gap: 2rem;
    border-top: 0.5px solid #aaaaaad2;
    align-items: center;
}

.paginationElement {
    color: #666666;
    cursor: pointer;
    padding: 1rem 1em 0rem 1rem;
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Noto Sans";
}

.move {
    all: unset;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    padding: 1rem 1em 0rem 1rem;

    color: #017F7C;
    font-weight: bold;
    white-space: nowrap;

}

.activeElement {
    color: black;
    font-weight: bold;
    border-top: 2px solid #017F7C;
}

.move:hover {
    text-decoration: underline;
}


.move[disabled] {
    color: #017f7da1;
    text-decoration: none !important;
}

@media only screen and (max-width: 991px) {
    .paginationContainer {
        display: none;
    }
}