@import "../../../styles/global";

.starStats {
  &Container {
    background-color: $swg-pearl;
    padding: 1rem;
    margin-right: 1rem;
    height: 100%;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    select {
      padding: 0.5rem;
      border-color: $swg-pebble;
      border-radius: 4px;
    }
  }

  &Icon {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 5px;

    font-size: 1.5rem;
    color: $swg-sun;
  }

  &Stat {
    font-size: 1.5rem;
    color: $swg-stone;
    font-weight: $font-weight-bold;
  }
}
