$textSizes: (
	"xs": ".75rem",
	"sm": ".875rem",
	"md": ".875rem",
	"base": "1rem",
	"lg": "1.125rem",
	"xl": "1.25rem",
	"2xl": "1.5rem",
	"3xl": "1.875rem",
	"4xl": "2rem",
	"5xl": "2.25rem",
	"6xl": "3rem",
	"7xl": "4rem",
	"8xl": "5rem",
	"9xl": "6rem",
);

@each $var, $val in $textSizes {
	.swg-text-#{$var} {
		font-size: var(--text-#{$var});
		@include isIe11 {
			font-size: #{$val};
		}
	}
}

:root {
	@each $var, $val in $textSizes {
		--text-#{$var}: #{$val};
	}
}
