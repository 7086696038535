.teamMemberVwq {
    :global {
        .Profile_module_profile__info__606e34c9 {
            background-color: #000A3B;
            font-family: "Noto Sans";
            // font-weight: 600;
            font-size: 14px;
            line-height: 21px;
        }
        .InfoPanel_module_primary__0dbed20a {
            font-family: "Noto Sans";
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: #666666;
        }
      }
}
