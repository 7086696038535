@import "../../styles/global";
.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem 0.625rem 0.25rem;

  background-color: $swg-pearl;

  border: 1px solid $swg-pebble;
  border-radius: $border-radius;
  @include isIe11 {
    display: block;
  }
}

.socialContent {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  &Desc {
    flex: 1 1 auto;
  }
}

.linksContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  align-items: center;

  > div {
    margin-top: 1rem;
  }

  > div:nth-child(1) {
    margin-right: 1rem;
  }
}

.appsImages {
  width: 8rem;

  border-radius: 0.5rem;
}
.socialAppsImages {
  margin-top: 2px;
  width: 12rem;
}

.socialLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;

  color: $white;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;

  border-radius: 50%;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;

  &.facebook {
    background-color: $facebook;
  }

  &.twitter {
    background-color: $twitter;
  }

  &.youtube {
    background-color: $youtube;
  }

  &.pinterest {
    background-color: $pinterest;
  }

  &.instagram {
    @include instagram-bgr;
  }

  @media (hover: hover) {
    &:hover {
      color: $white;
    }
  }
}

.socialHead {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;

  &Title {
    margin-bottom: 0;
  }

  &Icon {
    margin-right: 1.25rem;

    color: $swg-pebble;
  }
}

.socialHeadWithIcon {
  margin-bottom: 1rem;

  .socialHeadContent {
    display: flex;
    align-items: center;

    line-height: 1;

    @media (min-width: 752px) and (max-width: 900px) {
      display: block;
    }
    @media (max-width: 451px) {
      display: block;
    }
  }

  .socialHeadTitle {
    margin-right: 0.5rem;

    @media (max-width: 900px) {
      margin-bottom: 0.5rem;
    }
  }
}

.icon {
  position: absolute;

  width: 2.5rem;
  height: 2.5rem;

  color: $white;

  font-size: 1.5rem;

  line-height: 2.5rem;
}

.button {
  & + & {
    @include media-breakpoint-up(xs) {
      margin-left: 1rem;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}

.secondary {
  background-color: $white;

  h3 {
    span {
      display: block;
      margin-bottom: 1.5rem;

      font-size: 1.125rem;
    }
  }

  .linksContainer {
    height: auto;
  }
}
