@import "../../styles/global";

.messages {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  float: left;
  width: 100%;
}

.messages ul {
  list-style-type: none;
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.messages li {
  width: 100%;
}

.messages a:link {
    color: blue;
}

.messageHeader {
  color: #186aa7;
  font-weight: bold;
}

.messageArrow {
  float: right;
}

.messageHeaderDate {
  font-size: 0.75rem;
}

.messageFootDate {
  font-size: 0.75rem;
  float: right;
}

.messageContainer {
  display:block;
  @media (min-width: 752px) and (max-width: 900px) {
      flex-direction: column;
  }
  @media (max-width: 451px) {
      flex-direction: column;
  }
}

.flexedImage {
  padding-top: 10px;
}

.messageContent {
  display:block;
  padding-top: 10px;
  a:link {
    color: blue;
  }
  
  /* visited link */
  a:visited {
    color: blue;
  }
  
  /* mouse over link */
  a:hover {
    color: hotpink;
  }
  
  /* selected link */
  a:active {
    color: blue;
  }
}