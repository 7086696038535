@import "../../styles/global";

.horizontalCard {
  .horizontalCardContainer {
    display: flex;
    text-align: justify;

    max-height: 200px;
    max-height:200px;
    
    @media screen and (max-width: 600px) {   
      flex-direction: column;
      max-height: auto;

      padding-bottom:25rem;
    }
  }

  .imageContainer {
    flex: 1;
    overflow: hidden;

    img {
      object-fit: cover;
      max-width:100%;
      max-height:200px;

      @media screen and (max-width: 600px) {   
        height:200px; 
        min-height:200px;
        padding-top: 1rem;
      }
    }

    @media screen and (max-width: 600px) {   
      min-width: 100%; 
      min-height:200px;
    }
  }

  .contentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 20px;
    font-family: "Noto Sans";
    padding: 1rem;
    text-align: left;
    max-height: 200px;

    @media screen and (max-width: 600px) {   
      min-width: 100%; 
    }

    @include media-breakpoint-down(sm) {
      background-color: white;
    }

    &Text {
      flex: 1 1 auto;
      font-family: "Noto Sans";
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color:#666666;
    }

    .link {
      font-family: "Noto Sans";
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #017F7C;
       &:hover {
        color: #017F7C;
      }
    }

    .linkText {
      font-size: 14px;
      margin-right: 1rem;
    }

    .linkIcon {
      font-size: 0.9rem;
    }

    .buttonLink {
      padding: none;
      box-shadow: none;
      margin-top: 2rem;
      font-weight: normal;
    }

    .heading {
      margin: 0;
      padding: rem(10px) rem(1px);
      color: #000;
      font-family: "Noto Sans";
      font-size: 22px;
      font-weight: 300;
      line-height: 28px;      
    }
  }
}
