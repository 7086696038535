@import "../../../styles/global";

.star-balance {
  background-color: $swg-pearl;
  padding: 1rem;

  &__header {
    color: $swg-sun;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    margin-bottom: 0;

    h2 {
      margin: 0;
      font-size: 1.7em;
      font-weight: bold;
    }

    // &--split {
    //   display: flex;
    //   flex: 1 0 auto;
    //   flex-wrap: wrap;
    //   justify-content: space-between;

    //   h2 {
    //     margin-right: 15px;
    //     margin-bottom: 10px;

    //     //   @include screen-sm {
    //     //     margin-bottom: 0;
    //     //   }
    //   }

    //   h3 {
    //     margin: 0;
    //     font-size: 1em;
    //     font-weight: bold;
    //   }

    //   p {
    //     margin: 0;
    //   }
    // }
  }

  &__main-container {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__min-redemption {
    margin: 0.5rem 0 0;
    color: $swg-rock;
    font-weight: $font-weight-bold;
    font-size: 0.85rem;
  }

  &__meter {
    padding: 15px 0;

    .ant-slider {
      margin-left: 0;
    }

    .ant-slider-disabled,
    .ant-slider-disabled * {
      cursor: default !important;
    }

    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 45px;
    }

    .ant-slider:hover {
      .ant-slider-rail {
        background-color: #fff;
      }
    }

    .ant-slider-rail {
      box-shadow: 0 0 3px #00000055;
      background-color: #fff;
    }

    .ant-slider-track {
      background: linear-gradient(to bottom, $swg-sun 50%, #de3c00);
      position: relative;
      border-radius: 0;

      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 45px;
        border-radius: 14px;
        background-color: black;
      }
    }

    .ant-slider-dot {
      height: 41px;
      width: 1px;
      border: 0;
      border-radius: 0;
      top: -95%;
      background-color: rgba(255, 255, 255, 0.3);

      // Targets minimum redemption marker

      &:first-child,
      &:nth-child(2),
      &:last-child {
        background-color: transparent;
      }
    }

    .ant-slider-mark {
      top: 50px;

      &-text {
        color: $swg-rock;
      }

      &-text:first-child {
        transform: translateX(0) !important;
      }

      &-text:last-child {
        transform: translateX(-100%) !important;
        white-space: nowrap;
      }
    }     

    &-tooltip {
      font-size: 14px;
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.65);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0 15px 8px;
      list-style: none;
      position: absolute;
      z-index: 10;
      display: block;
      opacity: 1;
      visibility: visible;
      transform: none;
      animation: none !important;

      &-arrow {
        position: absolute;
        bottom: 3px;
        left: 50%;

        &::before,
        &::after {
          content: " ";
          top: -7px;
          left: 50%;
          border: solid transparent;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &::before {
          border-color: rgba(243, 111, 33, 0);
          border-top-color: #f36f21;
          border-width: 8px;
          margin-left: -8px;
        }

        &::after {
          border-color: rgba(255, 255, 255, 0);
          border-top-color: #fff;
          border-width: 5px;
          margin-left: -5px;
        }
      }

      &-inner {
        padding: 6px 8px;
        text-align: left;
        text-decoration: none;
        background-color: #fff;
        border: 2px solid $swg-sun;
        min-height: 32px;
      }

      &-balance {
        white-space: nowrap;
        span {
          margin-left: 5px;
          font-weight: bold;
          font-size: 1.5em;
        }
      }
    }
  }

  &__load {
    h3 {
      font-size: 1em;
      font-weight: bold;
      margin: 0;
      margin-bottom: 5px;
    }

    // > p {
    //   padding: 15px;
    //   margin: 0;
    // }

    .alert {
      color: red;
      margin-bottom: 0.5rem;
    }

    // STAR card number display
    &-number {
      font-size: 0.9em;
    }

    // &-container {
    //   padding: 15px;
    //   background: #d0e2ec;
    // }

    &-slider {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      margin-bottom: 15px;

      > .ant-slider {
        margin: 15px 15px 20px;

        .ant-slider-rail {
          background-color: #fff;
          border-radius: 4px;
          height: 10px;
        }

        .ant-slider-track {
          background: $swg-sun;
          height: 10px;
        }

        .ant-slider-step {
          height: 10px;
        }

        .ant-slider-handle {
          width: 30px;
          height: 30px;
          margin-top: -10px;
          margin-left: -17px;
          border: 3px solid $swg-sun;

          &:focus {
            box-shadow: 0 0 0 5px $swg-tide;
            outline: none;
          }
        }

        &:hover {
          .ant-slider-rail {
            background: #fff;
          }

          .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: $swg-sun;
          }
        }
      }

      > .ant-input-number {
        width: 100%;

        //   @include screen-sm {
        //     flex-basis: 20%;
        //   }
      }
    }

    &-value {
      p {
        margin: 0;
        font-size: 1.3em;
        font-weight: bold;
        color: $swg-sun;
      }
    }
  }

  &__points {
    font-size: 1.5rem;
    color: $swg-stone;
    font-weight: $font-weight-bold;
    margin: 2rem 0;
  }

  &__load {
    &-container {
      display: flex;
      align-items: flex-end;

      @media (max-width: 765px) {
        flex-wrap: wrap;
      }

      p {
        margin: 0;
        font-size: 0.85rem;
        color: $swg-stone;
        font-weight: $font-weight-normal;
      }

      button {
        align-self: flex-end;
        margin-right: 0.5rem;
        margin-bottom: 2px !important;
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;

        @media (max-width: 765px) {
          margin-top: 0 !important;
        }
      }
    }

    &-amount {
      margin-right: 0.5rem;

      input,
      .ant-input-number {
        width: 160px;
        min-height: 40px;

        border-radius: 4px;
      }
    }

    &-value {
      margin-right: 0.5rem;
    }

    &-cash {
      box-sizing: border-box;
      padding: 10px 30px 5px 10px;
      width: 160px;
      min-height: 42px;
      background-color: $swg-white;
      border: 1px solid $swg-pebble;
      border-radius: 4px;
    }

    &-btns {
      @media (max-width: 765px) {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  &__btns {
    margin: 2.5rem 0 0 0;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    margin-right: 0.5rem;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }

  &__note {
    margin-top: 1.5rem;
  }

  &__success-container {
    background-color: #ebf2ed;
    padding: 0.5rem 1rem;
    color: #046239;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    span {
      margin: 0 0.5rem 3px 0;
    }

    button {
      background-color: transparent !important;
      color: $swg-stone !important;
      font-size: 1rem;
      margin-top: 2px;

      &:hover {
        box-shadow: 0 0 0 transparent;
      }
    }
  }

  &__not-registered {
    & > div {
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    button {
      margin-top: auto;

      @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
      }
    }

    img {
      border-radius: 4px 4px $wing-corner 4px;
      max-height: 290px;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
  }

  &__cta-btns {
    display: flex;
    justify-content: center;
    margin: 3rem 0;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    button,
    a {
      margin: 0 0.5rem !important;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        width: calc(50% - 1rem);
        margin-bottom: 1rem !important;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:first-child {
        @include media-breakpoint-down(md) {
          margin-bottom: 1rem !important;
        }
      }
    }
  }

  &__loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 10000;
  }

  &__modal {
    background-color: $swg-white;

    button {
      background-color: transparent !important;
    }

    &-content {
      margin-top: 0 !important;
      padding: 0 !important;

      p:first-child {
        margin: 0;
        font-size: 1.5rem;
        background-color: rgba(217, 237, 247, 0.5);
        color: $swg-rock;
        padding: 1rem 1.5rem;
      }

      p:nth-child(2) {
        margin-top: 2rem;
      }

      p {
        padding: 0 1.5rem;
      }

      ul {
        padding-left: 4rem;

        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }
  }
}
