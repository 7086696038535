@import "../../styles/global";

.modal {
  margin-top: 10px !important;
  margin: 10px;
  padding: 0 !important;
  background-color: $swg-white;

  &Grid {
    padding: 0 1rem;

    &Names {
      margin-top: 1rem;

      &::before {
        content: "";
        display: block;
        width: calc(100% - 2rem);
        margin: 0 auto;
        height: 1px;
        background-color: #ebeaea;
        margin-bottom: 1rem;
      }
    }

    &Contact {
      align-items: flex-end;
    }    
  }

  &Title {
    margin-top: 0;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    color: $swg-rock;
    padding: 1rem;
    width: 100%;
    background-color: rgba(217, 237, 247, 0.5);
  }

  &Desc {
    margin-top: 0;
  }

  &List {
    padding-left: 2.5rem;
  }

  img {
    max-height: 300px;
  }

  label {
    display: block;
    font-weight: $font-weight-bold;
    margin-bottom: 0.25rem;

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }

    &[name="email"] {
      p {
        font-weight: $font-weight-bold;
        margin-bottom: 0;
      }
    }

    &[name="address"],
    &[name="city"],
    &[name="province"],
    &[name="postalCode"] {
      margin-top: 1rem;
    }

    span {
      display: block;
      font-weight: $font-weight-normal;
      font-size: 0.8rem;
    }
  }

  input {
    width: 100%;
    padding: 0.4rem;
    margin-bottom: 0.5rem;
    border: 1px solid $swg-pebble;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    &:read-only {
      background-color: $swg-pearl;
    }
  }
  select {
    padding: 0.45rem;
    border-color: $swg-pebble;
    width: 100%;
  }

  &Confirm {
    display: flex;
    align-items: flex-start;
    padding: 1rem;

    button {
      width: calc(100% * 1 / 3 - 0.5rem);
      margin-right: 0.5rem;
    }

    p {
      width: calc(100% * 2 / 3 - 0.5rem);
      margin-left: 0.5rem;
      margin-top: 0;
    }
  }
}

.successful {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left:1rem;
  font-weight: bold;
  color: $swg-green;
  font-size:1.25rem;
}

.error {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  color: $error;
  font-size: 1.25rem;
}

.errorEmpty {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  height: 22px;
}

.required::after {
  color: $error;
  font-size: $font-size-base;

  content: " *";
}
