@import "../../styles/global";
.feedbackModal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;

	display: inline-flex;
	align-items: center;
	height: 100%;
	overflow: auto;

	background-color: rgba($swg-white, 0.8);
}

.modalDialog {
	position: relative;

	display: flex;
	flex-direction: column;
	width: 100%;
	margin: auto;
	padding: 0;

	background-color: $white;
	box-shadow: 0 0 1.5rem rgba($swg-black, 0.25);
	@include media-breakpoint-up(lg) {
		max-width: 600px;
	}
	@include media-breakpoint-between(sm, md) {
		max-width: 70%;
	}
	@include media-breakpoint-down(sm) {
		max-width: 95%;
	}
}

.modalContent {
	position: relative;

	margin: 1rem;
	padding: 1rem;

	border: 1px solid $swg-pebble;
}

.modalHeader {
	.modalTitle {
		display: inline-block;
		margin-right: 2rem;

		font-weight: $font-weight-bold;

		&--center {
			display: block;

			text-align: center;
		}
	}

	.close {
		position: absolute;
		top: 1rem;
		right: 1rem;

		background: transparent;
		border: 0;
	}
}

.modalBody {
	padding: 1rem 0;
}

.modalFooter {
	padding: 1rem 0 0;

	text-align: center;

	.dismissGroup {
		text-align: left;
	}
}

.saveGroup {
	padding-top: 1rem;

	text-align: center;

	.saveButton {
		min-width: 8rem;
		margin: 0 0 0 1rem;
	}

	@media (max-width: 400px) {
		display: flex;
		flex-direction: column;

		.cancelButton {
			width: 100%;
			margin-right: 0;
		}

		.saveButton {
			width: 100%;
			margin-top: 1rem;
			margin-left: 0;
		}
	}
}

.loader {
	display: flex;
	margin: 0 auto;
}
