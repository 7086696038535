.expendAllContainer {
    text-align: right;
    width: 100%;
}

.expendAllBtn {
    border: none;
    background-color: transparent;
    color:#017F7C;
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;

}

.expendAllIcon {
    vertical-align:middle;
    color:#017F7C;
}

.infoContainer {
    text-align: left;
    float: left;
    width: 100%;
    border-top: 1px solid #DBDCDC;
    padding-top: 10px;
    padding-bottom: 5px;
}

.infoHeader {
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #000000;
}

.infoBtn {
    border: none;
    background-color: transparent;
    color:#017F7C;
    float: right;
}
