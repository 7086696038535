@import "../../styles/global";
.activateStarCard{
    background-color: $swg-pearl; 
    padding: 1rem;

    &Heading {
    position: relative;
    font-weight:bold ;

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      width: 100%;
    }

    h4 {
      position: absolute;
      top: 10px;
      left: 178px;
      width: 100%;

      @include media-breakpoint-down(md) {
        position: static;
      }
    }
  }
}
.starCard {
  background-color: $swg-pearl;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    display: block;
  }

  padding: 0rem; 

  &Image {
    width: 162px;
    margin: 10px auto 0;

    @include media-breakpoint-down(md) {
      max-width: 90px;
      margin-right: 1rem;
    }
  }

  &Field {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 1rem;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      margin: 1rem 0 0;
    }

    h3 {
      margin-bottom: 0;
    }

    &Item {
      margin-right: 1rem;

      p {
        color: $swg-rock;
        margin-bottom: 0;
        position: relative;
        white-space: nowrap;

        .budget {
          margin: 0 2px 2px 0;
        }
      }

      p:nth-child(2) {
        font-size: 1.5rem;
        color: $swg-stone;
        margin: 0.5rem 0;

        .highlighted {
          font-size: 1.5rem;
          color: $swg-sun;
        }
      }

      .highlighted {
        font-size: 1.2rem;
      }    

      input[type="text"]{
        display: block;
        width: 100%;
        height: 2.5rem;
        padding: 0.375rem 0.75rem;
    
        font-size: 1rem;
        font-family: inherit;
        line-height: 1.5;
    
        background-color: $white;
    
        border: 1px solid $swg-pebble;
        border-radius: 0.25rem;
        box-shadow: none;
    
        appearance: none;
    
        &[readonly] {
          background-color: $swg-pearl;
          cursor: not-allowed;
        }
      }

      @include media-breakpoint-down(sm) {
        width: calc(50% - 3rem);
        margin-bottom: 1.5rem;
      }
    }
  }

  &Ctas {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
      width: 160px;
    }

    @include media-breakpoint-down(md) {
      align-items: center;
    }

    button {
      margin-bottom: 1rem;

      @include media-breakpoint-down(md) {
        margin: 1.5rem auto 1rem;
        width: 50%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    a {
      text-decoration: none;
      color: $swg-tide;

      font-family: $font-family-muller-narrow;
      font-size: 1.15rem;

      margin-bottom: 0.5rem;

      span {
        font-size: 0.8rem;
        margin-bottom: 2px;
      }
    }
  }

  .highlighted {
    color: $swg-sun;
  }
}

.starCardUnregistered {
  &Desc {
    align-self: flex-end;
    flex-grow: 1;
    margin-left: 1rem;

    p {
      margin-bottom: 1rem !important;
    }
  }

  &Icon {
    color: $swg-sun;
    margin: 0 0.5rem 3px 0;
  }

  &Ctas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 180px;

    @include media-breakpoint-down(md) {
      align-items: center;
    }

    button {
      @include media-breakpoint-down(md) {
        margin: 1.5rem auto 0;
        width: 50%;
      }

      @include media-breakpoint-down(sm) {
        width: auto;
        margin-bottom: 1rem;
      }
    }

    a {
      text-decoration: none;
      color: $swg-tide;

      font-family: $font-family-muller-narrow;
      font-size: 1.15rem;

      margin-top: 1rem;

      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }

      span {
        font-size: 0.8rem;
        margin-bottom: 2px;
      }
    }    
  } 
}

.successfulMessage {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left:1rem;
    font-weight: bold;
    color: $success;
    font-size: 0.95rem;
  }

.errorMessage {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left:1rem;
    font-weight: bold;
    color: $error;
    font-size: 0.95rem;
  }


