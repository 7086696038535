
@mixin heading-color {
	color: $swg-stone;
}
@mixin heading-weight {
	font-weight: $font-weight-bold;
}
@mixin h1 {
	@include heading-weight;
	@include heading-color;
	font-family: $font-family-muller-narrow;
	text-transform: uppercase;
	@include media-breakpoint-up(md) {
		@include fontSize(44px, 42px);
	}
	@include media-breakpoint-down(sm) {
		@include fontSize(34px, 29px);
	}
}

@mixin h1alt($flat: true) {
	@include heading-weight;
	@if $flat == true {
		@include heading-color;
	}
	@include fontSize(34px, 30px);
	font-family: $font-family-muller-narrow;
	text-transform: uppercase;
}


@mixin h2 {
	@include heading-weight;
	@include heading-color;
	font-family: $font-family-base;
	text-transform: none;
	@include media-breakpoint-up(md) {
		@include fontSize(34px, 42px);
	}
	@include media-breakpoint-down(sm) {
		@include fontSize(24px, 32px);
	}
}


@mixin h3($flat: true, $alt: false) {
	@if $flat == true {
		@include heading-weight;
		@include heading-color;
	}
	@if $alt == true {
		font-family: $font-family-muller-narrow;
	}
	@include media-breakpoint-up(md) {
		@include fontSize(24px, 32px);
	}
	@include media-breakpoint-down(sm) {
		@include fontSize(20px, 24px);
	}
}

@mixin h4($color: true) {
	@include fontSize(20px, 20px);
	@include heading-weight;
	@if $color == true {
		@include heading-color;
	}
	font-family: $font-family-muller-narrow;
	text-transform: uppercase;

}

@mixin h5 {
	@include fontSize(20px, 24px);
	@include heading-color;
	font-weight: $font-weight-normal;
	font-family: $font-family-muller-narrow;
}


@mixin ui-body {
	@include fontSize(16px, 22px);
	font-weight: $font-weight-normal;
}

@mixin ui-caption {
	@include fontSize(14px, 18px);
	font-style: normal;
}

@mixin ui-small {
	@include fontSize(12px, 14px);
	font-style: normal;
}

