.body {
  margin-top: 1rem;
}

.social-button {
  margin-right: 0.5rem;
}

.image {
  img {
    object-fit: cover;
    border-radius: 4px;
  }
}
