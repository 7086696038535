@import "../../styles/global";

.contactUs {
  margin-top: 3rem;

  hr {
    border: 0;
    margin: 1rem 0;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: $swg-pebble;
    }
  }
}

.contactUsVwq {

  font-family: "Noto Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  :global {  
    .Heading_module_heading_Center__9ad4c810 {
      text-align: left;
      font-family: "Noto Sans";
      font-weight: 200;
      font-size: 60px;
      line-height: 72px;

      @media (max-width: 600px) {
        color: #000;
        font-family: "Noto Sans";
        font-size: 40px;
        font-weight: 200;
        line-height: 48px;
        text-transform: none;
      }
    }
  }

  p {
    font-family: "Noto Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }
}
