@import "../../styles/global";

.loginContainer {
  background-image: url('https://assets.sunwingtravelgroup.com/image/upload/v1715351285/WestJet/EMAIL/Agent%20Portal/Images/SWG_VWQ_Agent_Portal_Main.jpg');
  background-size: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.loginSection {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
}

.login {
  display: flex;
  
  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }

  &Info {

    @include media-breakpoint-down(md) {
      width: 500px;
      margin: 3rem auto;
    }

    @media (max-width: 600px) {
      max-width: 400px;
    }

    @media (max-width: 480px) {
      width: auto;
      min-width: 300px;
    }

    font-family: "Noto Sans";
    &Feature {

      &Title {
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        @media (max-width: 600px) {
          font-family: "Noto Sans";
          font-weight: 900;
          font-size: 16px;
          line-height: 20px;
        }
      }

      &Context {
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          @media (max-width: 600px) {
            font-family: "Noto Sans";
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
          }
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        }
      }

      &Item {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        margin-left: 0.6rem;
        color: white;
  
        .featureIcon {
          padding-right: 1rem;
  
          .svgIcon {
            display: inline-block;
            vertical-align: baseline;
            color: $swg-sun;
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .widgetWrapper {
    flex: 1 1 50%;
  }

  :global {
    
    .HeroCard_module_heroHeading__455b3e1f {
      font-family: "Noto Sans";
      font-weight: 900;
      font-size: 56px;
      line-height: 68px;

      @media (max-width: 600px) {
        font-family: "Noto Sans";
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
      }
    }
  }
}