@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100..900&family=Noto+Serif:ital,wght@1,100..900&display=swap");
@import "./global/functions";
@import "./global/variables";
@import "./global/vendor/bootstrap";
@import "./global/mixins";
@import "./global/typo-mixins";
@import "./global/typography";
@import "./global/themes";
@import "./global/sizes";
@import "./global/layouts";
@import "@sunwing/icons/dist/vacances-westjet-quebec/scss/icons.scss";

