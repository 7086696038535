@import "../../styles/global";

.platinumClubContainer {
    background-color: #f2f2f2;
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
}

.platinumClubRewardDetails
 {
    width: 98%;
    min-width: 700px;
    margin: 0;
    line-height: rem(18px);
    table-layout:auto;
    border: 0px  none;
    thead {
        font-weight: bold;
    }
    th, tr, td {
        align-items: center;
        text-align: center;
    }
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
}
.platinumClubTable {
    width: 98%;
    min-width: 700px;
    margin: 0;
    line-height: rem(18px); 
    table-layout:auto;
    border: 1px solid black;
    td:first-child {
        min-width: 200px
    };
    thead {
        font-weight: bold;
    }
    th, tr, td {
        border:1px solid black;
        align-items: center;
        text-align: center;
    }
}

.voucherDetailPopupContainer {
    width: 50%;
    height: 40%;
    background-color: white;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;   
    margin: auto;
    border-width: 1px;
    border-style: solid;
    border-color: aqua ;
    padding: 10px;

}
.voucherDetailFirstTable {
    margin: 0 auto;
    width: 80%;
    border-style: none;
    border-width: 0px;
    tr {
        border-style: none;
        border-width: 0px;
    }
    td {
        border-style: none;
        border-width: 0px;
        text-align: left;
    }
}
.voucherDetailsecondTable {
    margin: 0 auto;
    width: 80%;
    text-align:center;
    thead {
        font-weight: bold;
    }
    th, tr, td {
        border: 1px solid black;
    }
}

.platinumClubHeading {
    font-family: "Noto Sans";
    font-size: 60px;
    font-weight: 200;
    line-height: 72px;
    text-transform: none;
}



