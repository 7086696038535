$themes-colors: (
	"inherit": "inherit",
	"current": "currentColor",
	"sun": $swg-sun,
	"dune": $swg-dune,
	"stone": $swg-stone,
	"tide": $swg-tide,
	"rock": $swg-rock,
	"green": $swg-green,
	"water": $swg-water,
	"pebble": $swg-pebble,
	"sand": $swg-sand,
	"sky": $swg-sky,
	"pearl": $swg-pearl,
	"sea-shell": $swg-sea-shell,
	"star-fish": $swg-star-fish,
	"coral": $swg-coral,
	"coral-reef": $swg-coral-reef,
	"royal": $swg-royal,
	"sea": $swg-sea,
	"turquoise": $swg-turquoise,
	"beach": $swg-beach,
	"wedding": $swg-wedding,
	"shadow": $swg-shadow,
);

$system-colors: (
	"error": $error,
	"error-light": $light-error,
	"success": $success,
	"success-light": $light-success,
	"warning": $warning,
	"warning-light": $light-warning,
);

$social-colors: (
	"facebook": $facebook,
	"twitter": $twitter,
	"pinterest": $pinterest,
	"youtube": $youtube
);


@mixin outputFlatVars($color) {
	@each $var, $val in $color {
		.bg--#{$var} {
			background-color: var(--color-#{$var});
			@include isIe11 {
				background-color: $val;
			}
		}
	}
	@each $var, $val in $color {
		.text--#{$var} {
			color: var(--color-#{$var});
			@include isIe11 {
				color: $val;
			}
		}
	}
}

@function map-deep-get($map, $keys...) {
	@each $key in $keys {
		$map: map_get($map, $key);
	}
	@return $map;
}

@include outputFlatVars($themes-colors);
@include outputFlatVars($social-colors);
@include outputFlatVars($system-colors);

:root { // TODO: Enable this after we make this global
	// .colors__storybook {
	@each $var, $val in $themes-colors {
		--color-#{$var}: #{$val};
	}
	@each $var, $val in $system-colors {
		--color-#{$var}: #{$val};
	}
	@each $var, $val in $social-colors {
		--color-#{$var}: #{$val};
	}
}
