@mixin section-heading {
	color: $font-color;
	font-weight: 700;
	font-size: 2.75rem;
	font-family: "MullerNarrow", sans-serif;
	text-align: center;
	text-transform: uppercase;
}

@mixin subheading-uppercase($color) {
	color: $color;
	font-weight: 700;
	font-size: 1.5rem;
	text-align: left;
	text-transform: uppercase;
}

// Card mixins // TODO: use/update this mixin for .heading classes
@mixin card-heading(
	$textTransform: "uppercase",
	$color: $swg-rock,
	$fontWeight: 700,
	$fontSize: "24px"
) {
	color: $color;
	font-weight: $fontWeight;
	font-size: $fontSize;
	font-family: "MullerNarrow", sans-serif;
	line-height: rem(20px);
	text-align: left;
	text-transform: $textTransform;
}

@mixin wing-border {
	border-radius: 4px 4px $wing-corner 4px;
}

@mixin border-shadow {
	box-shadow: 0 7px 21px $swg-shadow;
}
@mixin wing-border-shadow {
	@include wing-border;

	@include border-shadow;
}

@mixin visible-border {
	@include border-radius($color: #c5c5c5);

	border: 1px solid $color;
}

// Fluid Type Logic
// 14px - minimum size at the smallest viewport width of 300px
// 26px - maximum size at the largest viewport width of 1600px
// font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));

// min, max, letter spacing

@mixin fluid-type($min, $max) {
	font-size: calc(#{$min}px + (#{$max} - #{$min}) * ((100vw - 320px) / (#{strip-unit($large1)} - 320)));
}

// USAGE:
// This works ONLY with Pixels:
// font-size | line-height
@mixin fontSize($size, $lh: false) {
	font-size: rem($size);
	@if $lh {
		line-height: rem($lh);
	}
}

@mixin isSafari() {
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) and (stroke-color: transparent) {
			@content;
		}
	}
}

@mixin isIe11() {
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		@content;
	}
}

@mixin default-button-style {
	color: $swg-white;

	background-color: $swg-tide;
}

@mixin button-outline-style($color, $bgrOpacity) {
	color: $color;

	background-color: $swg-white;
	border: 1px solid currentColor;
	@media (hover: hover) {
		&:hover {
			color: $color; // TODO: remove this once FIXME above is done

			background-color: lighten($color, $bgrOpacity);
			box-shadow: none;
		}
	}
}

$chevronSizeMd: 14px;
$chevronSizeLg: 24px;
@mixin chevron($size: "md") {
	z-index: 0;

	display: block;
	-webkit-font-smoothing: antialiased;

	&::before,
	&::after {
		position: absolute;
		top: 50%;
		left: 0;

		height: 2px;

		background-color: currentColor;
		backface-visibility: hidden;
		opacity: 0.9;

		transition-duration: 0.2s;
		transition-property: transform, background;

		content: "";
	}

	&::before {
		border-top-left-radius: 1px;
		border-bottom-left-radius: 1px;
		transform: rotate(45deg);
	}

	&::after {
		border-top-right-radius: 1px;
		border-bottom-right-radius: 1px;
		transform: rotate(-45deg);
	}

	@if $size == "md" {
		width: $chevronSizeMd;
		height: $chevronSizeMd;

		&::before,
		&::after {
			width: 9px;
		}

		&::after {
			margin-left: 5px;
		}
	}
	@if $size == "lg" {
		width: $chevronSizeLg;
		height: $chevronSizeLg;

		&::before,
		&::after {
			width: 16px;
		}

		&::after {
			margin-left: 10px;
		}
	}
}

@mixin chevron-active {
	&::before {
		transform: rotate(-45deg);
	}

	&::after {
		transform: rotate(45deg);
	}
}

@mixin instagram-bgr {
	background: $instagram;
	background: linear-gradient(45deg,
	$instagram 0%,
	#e6683c 25%,
	#dc2743 50%,
	#cc2366 75%,
	#bc1888 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f09433", endColorstr="#bc1888", GradientType=1);
}

@mixin swg-container {
	max-width: rem(1140px);
	margin-right: auto;
	margin-left: auto;
}

@mixin cardLink($zIndex: 1) {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $zIndex;

	width: 100%;
	height: 100%;
	margin: 0;
	overflow: hidden;
	// Make sure the parent is relative

	&:hover {
		text-decoration: none;
	}
}

/*
|--------------------------------------------------
|              🚨🚨🚨 DO NOT USE 🚨🚨🚨
|          Required for ported components
|--------------------------------------------------
*/

@mixin btn-reset {
	display: inline-block;
	margin: 0;
	padding: 0;

	color: inherit;
	font-size: 1rem;
	font-family: $font-family-base;
	text-decoration: none;

	background: transparent;
	border: 1px solid transparent;

	cursor: pointer;

	transition: 0.25s ease-out;
	transition-property: background-color, border;
}

@mixin hover() {
	&:hover,
	&:focus,
	&:active {
		@content;
	}
}

@mixin link-hover($color: false, $colorHover: false,  $hover: true) {
	@if $color {
		color: $color;
	}
	text-decoration: none;

	transition: color 0.2s linear;

	@if $hover {
		@media (hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	@if $colorHover {
		@media (hover: hover) {
			&:hover {
				color: $colorHover;
			}
		}
	}
}

@mixin link-only {
	a:not([class^="Button-"]) {
		@content; }
}

@mixin sr-only {
	position: absolute;
	z-index: -1;

	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;

	border: 0;

	clip: rect(0, 0, 0, 0);
}

@mixin content-spacing {
	> * + * {
		margin-top: 1em;
	}
}


@mixin truncate($lines: 3) {
	/* stylelint-disable */
	/*! autoprefixer: off */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $lines;
	/*! autoprefixer: on */
	/* stylelint-enable */
	overflow: hidden;
	word-break: break-word;
}
