.brandSelectionPopupContainer {
    min-width: 20%;
    min-height: 10%;
    max-width: 50%;
    max-height: 50%;
    display:flex;
    background-color: white;
    position: fixed;
    right:60%;
    top: 45%;  
    margin: auto;
    border-width: 1px;
    border-style: solid;
    padding: 10px;
}

.dialog {
    max-width: 40rem;
    padding: 2rem;
    border: 0;
    position: relative;
    text-align: center;
}

.dialogTitle {
font-size: 2rem;
}

.dialogContent {
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;

}

.dialog::backdrop {
background: hsl(0 0% 0% / 50%);
}

.dialogButton {
background-color: #000A3B;
max-width: 40%;
min-width: 35%;
min-height: 35px;
border-radius: 50px;
margin-left: 0.8rem;
color: white;
margin-top: 0.5rem;
font-family: "Noto Sans";
font-size: 14px;
font-weight: 400;
line-height: 26px;
display: inline-block;

@media screen and (max-width: 600px) {   
  min-width: 70%; 
}

}

.westJetBar {
    max-width: 40rem;
    padding: 2rem;
    border: 0;
    text-align: center;
    margin-top: 30%;
    margin-left: 40%;
    position: relative;
}

.helpfulInfoToggleContainer {
    float: right;
    margin-right: 18%;
    top : 170px;
    position: relative;
    display: inline;
    color: #017F7C;
  }

  .helpfulInfoToggleIcon {
    display: inline-block;
    vertical-align:middle;
  }

  .helpfulInfoToggleButton {
    padding: 0;
    border: none;
    background: none;
  }

  :global {
    .HeroCard_module_heroImage__455b3e1f {
      border-radius: 0 0 0 0;
    }
}