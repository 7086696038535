@import "../../styles/global";

.sort-table {
  margin: 1rem 0;
  border-bottom: 1px solid $swg-pebble;
  border-radius: 4px;
  position: relative;

  .ant-table {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

    @include media-breakpoint-down(sm) {
      overflow-x: scroll;
    }
  }

  .ant-table-title {
    // position: absolute;
    // top: -150px;
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    align-items: center;
    border: 0;
    border-radius: 0;
    background-color: rgba(217, 237, 247, 0.5);
    padding: 15px;
    padding-top: 0;
    margin-bottom: 2rem;
    width: 100%;

    .sort-table__left {
      margin-top: 15px;
      width: 65%;

      @include media-breakpoint-down(md) {
        width: auto;
      }

      > h2 {
        font-size: 2em;
        font-weight: bold;
        margin: 0;
        color: $swg-tide;
      }

      > span {
        color: $swg-rock;
      }

      @include media-breakpoint-down(sm) {
        flex: 1 1 auto;

        button {
          width: 100%;
        }
      }
    }

    .sort-table__search {
      margin-top: 15px;
      margin-left: auto;
      width: 30%;

      @include media-breakpoint-down(md) {
        padding-left: 0;
        margin-left: 0;
        margin-top: 1.5rem;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      input {
        border-radius: 4px 0 0 4px;
        border-right: 0;
        font-size: 1rem;
        padding: 0.5rem;

        &::placeholder {
          color: $swg-rock;
        }
      }

      .ant-input-search-button {
        border-left: 0;
        color: $swg-tide !important;
        height: 43px;
        padding: 0.5rem;

        span {
          font-size: 20px;
        }
      }
    }
  }

  .ant-table-title + .ant-table-content {
    border-radius: 0;

    table {
      border-top: 0;
    }
  }

  .ant-table-body {
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 0.01%;
  }

  table {
    border-radius: 0;
    width: 100%;
    max-width: 100%;
  }

  .ant-table-container {
    border: 1px solid $swg-pebble;
    border-bottom: 0;
    border-radius: 4px !important;
  }

  .ant-table-content {
    border-top: 0;
  }

  .ant-table-placeholder {
    border: 0;
  }

  .ant-table-thead > tr > th {
    padding: 10px 15px;
    background: $swg-pearl;
    font-size: 16px;
    font-weight: bold;
    color: $swg-rock;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }

  .ant-table-tbody > tr > td {
    font-size: 16px;
    color: $swg-rock;
    // white-space: nowrap;

    &.multi-line {
      word-break: break-word;
      word-wrap: break-word;
      white-space: pre;
    }
  }

  td.ant-table-column-sort {
    background: inherit;
  }

  tr.ant-table-expanded-row > td {
    background-color: $swg-pearl;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: rgba(217, 237, 247, 0.5);
  }

  td.ant-table-cell:last-child {
    width: 350px;

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  .ant-table-column-sorter {
    // height: 30px;
    // width: 20px;
    // text-align: center;

    .anticon {
      position: absolute;
      left: 0;
      // top: 20px;
      // width: auto;
      // height: 17px;
      margin: 0;
      // margin-top: 10px;
      // width: 24px;
      height: 35px;
      // font-size: 25px;
      line-height: 1;
      color: #aeaeae;

      &::after {
        display: none;
      }

      @include hover {
        color: $swg-tide;
      }

      &.on {
        color: $swg-tide;
      }
    }

    .ant-table-column-sorter-up {
      top: 12px;

      @media (max-width: 950px) {
        top: 25px;
      }
    }

    .ant-table-column-sorter-down {
      bottom: -12px;

      @media (max-width: 950px) {
        bottom: 0;
      }
    }
  }

  .default-sorted-ascend ~ .ant-table-column-sorter {
    .ant-table-column-sorter-up {
      color: $swg-tide;
    }
  }

  .default-sorted-descend ~ .ant-table-column-sorter {
    .ant-table-column-sorter-down {
      color: $swg-tide;
    }
  }

  .no-expandable-source {
    .ant-table-row-expand-icon {
      display: none;
    }

    + .ant-table-expanded-row-level-1 {
      display: none;
    }
  }

  .ant-table-expanded-row .expanded-table {
    width: 100%;

    th {
      color: $swg-rock;
    }

    td {
      padding-top: 10px;
    }
  }

  .ant-pagination {
    border-left: 1px solid $swg-pebble;
    border-right: 1px solid $swg-pebble;
    margin: 0 !important;
    padding: 1rem;
  }

  .ant-pagination-item-link:after {
    display: none;
  }

  .ant-pagination-item-container {
    .ant-pagination-item-link-icon {
      display: none;
    }

    .ant-pagination-item-ellipsis {
      color: #d9d9d9;
    }

    @include hover {
      .ant-pagination-item-link-icon {
        display: inline-block;
      }

      .ant-pagination-item-ellipsis {
        display: none;
      }
    }
  }

  .ant-pagination-jump-next:after,
  .ant-pagination-jump-prev:after {
    display: none;
  }
}
