@import "../../styles/global";
.sso-widget-wrapper.bg {
	min-height: 500px;
	margin-top: -$wing-corner;
	padding: 5rem 0;

	background-image: url("https://assets.sunwingtravelgroup.com/image/upload/c_limit,w_1920/sunwing-prod/repo_min/sunwingca/account-profile/account-profile-sign-in.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@media (max-width: 600px) {
		margin-top: 0;
		padding: 0;
	}
}

.sso-widget {
	@media (max-width: 575px) {
		box-shadow: 0 0 2em rgba($swg-black, 0.5);
	}

	#okta-sign-in {
		width: auto;
		min-width: 300px;

		input[type="password"]::-ms-reveal,
		input[type="password"]::-ms-clear {
			display: none;
		}

		&.auth-container .auth-content {
			padding: 25px;
		}

		&.auth-container.main-container {
			margin: auto;

			font-family: $font-family-base;

			border: 0;
			outline: none;
			box-shadow: none;
		}

		.okta-form-infobox-error {
			display: flex !important;
		}

		.o-form-fieldset {
			font-family: "Noto Sans";
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
		}

		.o-form-explain.o-form-input-error {
			font-family: "Noto Sans";
			font-size: 14px;
			font-weight: 600;
			line-height: 21px;
		}

		&.auth-container .button-primary {

			background: #000A3B;
			border-radius: 40px;
			font-size: 20px;
			font-weight: 900;
			line-height: 21px;
			letter-spacing: 0.05em;
			color: #FFFFFF;
		}

		&.auth-container .button-primary:hover {
		 	box-shadow: 1px 1px 8px 0px #000A3B;
			background: #FF6000;
			font-size: 20px;
			font-weight: 900;
			line-height: 21px;
			border-radius: 40px;
			border-color: #000A3B;
			color: #000A3B;
		}
		
		.okta-sign-in-header.auth-header {
			display: none;
		}

		.o-form-explain.o-form-input-error,
		.okta-form-infobox-error {
			color: $error;
		}

		.o-form-explain.o-form-input-error .icon::before {
			color: $error;
		}

		.help-wrap .infobox.infobox-error::before,
		.infobox-error::before,
		.infobox.infobox-subtle.infobox-error::before {
			background-color: $error;
		}

		.focused-input,
		.link.help:focus {
			box-shadow: none;
		}

		.okta-form-title.o-form-head,
		.okta-form-label,
		label {
			color: $swg-rock;
		}
		a{
			color: #FF6000;
			font-size: 16px;
			font-weight: 400;
			line-height: 21px;
			text-align: left;
			text-decoration: underline;			
		}

		.okta-form-title.o-form-head {
			margin-top: 0;

			font-size: 40px;
			font-weight: 400;
			line-height: 48px;
			text-align: left;
			color: #010D1A;
		}

		&.auth-container .link,
		&.auth-container .link:active,
		&.auth-container .link:hover,
		&.auth-container .link:link,
		&.auth-container .link:visited {
			color: $swg-rock;
		}

		.registration .subschema .subschema-satisfied {
			color: $swg-rock;
		}

		.error-16-red::before,
		.error-16-small::before {
			color: $error;
		}

		.registration .subschema .subschema-error {
			color: $error;
		}

		.registration .o-form-fieldset-container .required-fields-label {
			color: $swg-rock;
		}

		.registration-container .registration-label,
		.auth-divider .auth-divider-text {
			color: $swg-rock;
		}

		.registration-container .registration-link {
			color: $swg-tide;
		}

		.legal {
			display: block;
			padding-top: 1rem !important;

			font-style: italic !important;
		}	

		/* Sign up section */
		.registration-container {
			margin-top: 15px;
		}

		.registration-container .content-container {
			padding: 0;

			border-top: 0;
		}

		@media (max-width: 600px) {
			&.auth-container .auth-content {
				max-width: 100%;
				padding: 15px;
			}

			.sso-widget {
				min-height: auto;
				padding: 0;
			}

			&.auth-container.main-container {
				border-radius: 0;
				box-shadow: none;
			}
		}

		.registration {
			// Custom consent field and label
			.o-form-fieldset-container .o-form-fieldset .custom-checkbox label {
				min-height: 2rem;
			}

			.o-form-fieldset-container .o-form-fieldset .custom-multiple-dropdown div{	
							

				.ant-select-selection-overflow{						
					border-width: max(1px, 0.1em);
                    border-style: solid; 
                    border-color: lightgray;
					min-height: 30px;
					min-width: 420px;
					vertical-align: middle !important;
					span{
						vertical-align: middle;
						background: lightgray;
						margin:1px;
					}			
				}

				.ant-select{					
					vertical-align: middle;
					min-width: 400px;
					span{
					vertical-align: middle !important;					
					}
				}
			}			

			.o-form-explain.o-form-input-error {
				word-break: normal;
			}

			.okta-form-input-field:hover {
				border: 1px solid #000A3B;  
			}
			.okta-form-input-field:focus {
				border: 1px solid #000A3B;    
			}
			
		}		

		.registration-complete .icon::before {
			line-height: unset;
		}

		// Okta Sign-in widget CSS updates for Social Media
		.primary-auth-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			& > a.social-auth-button.link-button {
				flex: 0 1 50px;
				max-width: 50px;
				padding-left: 0;

				border-radius: 2rem;
			}

			.auth-divider {
				flex-basis: 100%;
			}
		}

		.okta-sign-in-header.activate {
			display: inline-block;
			width: 100%;
			padding: 0;

			.account-activation {
				padding: 1rem 15px;

				text-align: center;

				background-color: $light-success;

				.heading {
					display: inline-flex;
					align-items: center;

					color: $success;
					font-weight: 700;
					font-size: 1.2rem;

					.swg-icon {
						margin-right: 0.5rem;

						font-size: 1.5rem;
						font-family: "swg-icons";
					}
				}

				.message {
					font-weight: $font-weight-base;
					font-size: 0.9rem;
				}
			}
		}
	}
}

// Added widget targeting for CLS fix on Login component
.sso-widget-wrapper {
	@media (min-width: 601px) {
		min-height: 670px;
	}
	@media (max-width: 600px) {
		min-height: 510px;
	}
}

/* Work-around to keep widget background under header */
@at-root header.page__header {
	position: relative;
}

// Target header z-index issue
.page__header {
	position: relative;
}

// Handle usecase when widget modal is displayed on login page and user cannot close overlay
.sso-disable-close {
	display: none;
}

.linkStyle{
	a{
    color:#1662dd;
    text-decoration: underline;
	padding-bottom:5px ;
	}
}
