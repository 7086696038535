.accordionVwq {
    .Accordion_module_defaultToggleContainer__dd5c06f4  {
        color: black;
        font-family: Noto Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
    }
    p, li {
        font-family: Noto Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;

    }
    .Accordion_module_accordionChevron__dd5c06f4  {
        color: #017F7C;
    }
}