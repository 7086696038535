.card_vwq {
    :global {
      .Heading_module_heading_Left__9ad4c810 {
        font-family: "Noto Sans";
        font-size: 22px;
        font-weight: 300;
        line-height: 28px;
        color:#000000;
        overflow: hidden;
        height: 80px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-top: 0.5rem;
      }
      
      //for dashboard bottom section
      .SecondaryCard_module_secondaryLinkText__76a1cc9b {
        font-family: "Noto Sans";
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #017F7C;
        padding-left: 0.1rem;
      }
      
      .SecondaryCard_module_secondaryImage__76a1cc9b img{
        height: 210px;
      }

      .SecondaryCard_module_secondaryBody__76a1cc9b {
        height: 160px;
      }
    }
  }
