p,
li {
	@include ui-body;
}

h1 {
	@include h1;
}

h2 {
	@include h2;
}

h3 {
	@include h3;
}

h4 {
	@include h4;
}

h5 {
	@include h5;
}

.ui-body {
	@include ui-body;
}

.ui-caption {
	@include ui-caption;
}

.ui-small {
	@include ui-small;
}

h6,
.heading-h6 {
	color: $swg-stone;
	font-weight: 400;
	font-size: rem(20px);
	font-family: $font-family-muller-narrow;

	@include media-breakpoint-down(md) {
		font-size: rem(20px);
	}
}

.heading-large {
	font-size: rem(25px);
}

small {
	font-size: rem(14px);
}

ul,
ol {
	padding-left: rem(17px);
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.uppercase {
	text-transform: uppercase;
}
