@import "../../styles/global";

.table {
  width: 100%;
  min-width: 700px;
  margin: 0;

  line-height: rem(18px);

  table-layout: fixed;
  border: 1px solid $swg-pebble;

  border-collapse: collapse;
  border-spacing: 0;

  @include media-breakpoint-down(md) {
    border-collapse: separate;
  }
  @include link-only {
    @include link-hover($swg-tide);
  }

  th {
    font-weight: 700;
  }
}

.header {
  padding: rem(12px);

  span {
    display: block;

    font-weight: 400;
    font-size: 0.8rem;
  }

  & + & {
    border-left: 1px solid $swg-pebble;
  }
}

.cell {
  padding: 0.75rem 0.5rem;

  border-top: 1px solid $swg-pebble;
  border-right: 1px solid $swg-pebble;
}

.description {
  color: inherit;
  text-align: left;
}

.subtext {
  clear: $swg-rock;
  margin: rem(15px) 0 0 0;

  font-size: rem(12px);
  line-height: rem(16px);
}

.background {
  background-color: $swg-pearl;
}

.bold {
  font-weight: 700;
}

.small {
  font-size: 0.75rem;
  line-height: 0.9rem;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.check {
  color: $swg-tide;
}

.minus {
  color: $swg-pebble;
}

.listItem {
  position: relative;

  padding-bottom: 1rem;

  padding-left: 25px;

  font-size: 0.9rem;
}

.listItemCheck {
  position: absolute;
  top: 2px;
  left: 4px;

  width: 0.75rem;
}

.sticky {
  @include media-breakpoint-down(md) {
    position: sticky;
    left: 0;

    width: 30vw;

    background-color: #fff;
    box-shadow: 10px 10px 10px 0 $swg-shadow;
  }
}
